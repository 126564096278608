export const APP_SET_ACCESS_TOKENS = 'APP_SET_ACCESS_TOKENS'
export const APP_SET_ALLOWED_CARRIERS = 'APP_SET_ALLOWED_CARRIERS'
export const APP_SET_CHECK_LABEL_STATUS_URL = 'APP_SET_CHECK_LABEL_STATUS_URL'
export const APP_SET_COMPLETED_ALL_STEPS = 'APP_SET_COMPLETED_ALL_STEPS'
export const APP_SET_DETACHED_REASON = 'APP_SET_DETACHED_REASON'
export const APP_SET_DOWNLOAD_LABEL_URL = 'APP_SET_DOWNLOAD_LABEL_URL'
export const APP_SET_QR_CODE_URL = 'APP_SET_QR_CODE_URL'
export const APP_SET_DROP_OFF_LOCATION_ID = 'APP_SET_DROP_OFF_LOCATION_ID'
export const APP_SET_DROP_OFF_POINTS = 'APP_SET_DROP_OFF_POINTS'
export const APP_SET_ERROR = 'APP_SET_ERROR'
export const APP_SET_LOCATION_TYPE = 'APP_SET_LOCATION_TYPE'
export const APP_SET_SELECTED_CARRIER_CODE = 'APP_SET_SELECTED_CARRIER_CODE'
export const APP_SET_PACKING_ITEMS = 'APP_SET_PACKING_ITEMS'
export const APP_SET_CARRIER_DROP_OFF_FINDER = 'APP_SET_CARRIER_DROP_OFF_FINDER'
export const APP_SET_PARCEL = 'APP_SET_PARCEL'
export const APP_SET_PARCELS_NUMBER = 'APP_SET_PARCELS_NUMBER'
export const APP_SET_PORTAL_SETTINGS = 'APP_SET_PORTAL_SETTINGS'
export const APP_SET_RETURNED_ITEMS = 'APP_SET_RETURNED_ITEMS'
export const APP_SET_SEARCH_PARAMS = 'APP_SET_SEARCH_PARAMS'
export const APP_SET_SELECTED_DROP_OFF_POINT = 'APP_SET_SELECTED_DROP_OFF_POINT'
export const APP_SET_SELECTED_LABELLESS_DROP_OFF_POINT = 'APP_SET_SELECTED_LABELLESS_DROP_OFF_POINT'
export const APP_SET_SELECTED_REFUND = 'APP_SET_SELECTED_REFUND'
export const APP_SET_STORE_ADDRESSES = 'APP_SET_STORE_ADDRESSES'
export const APP_SET_STORE_LOCATION_ID = 'APP_SET_STORE_LOCATION_ID'
export const APP_SET_SHIPPING_PRODUCTS = 'APP_SET_SHIPPING_PRODUCTS'
export const APP_SET_USER_LANGUAGE = 'APP_SET_USER_LANGUAGE'
export const APP_SET_NATIONAL_CARRIER = 'APP_SET_NATIONAL_CARRIER'
export const APP_SET_RETURN_ADDRESS = 'APP_SET_RETURN_ADDRESS'
export const APP_SET_BETA_FEATURES = 'APP_SET_BETA_FEATURES'
export const APP_SET_APPLICABLE_ACTIONS = 'APP_SET_APPLICABLE_ACTIONS'
export const APP_SET_PICKUP_DATE = 'APP_SET_PICKUP_DATE'
