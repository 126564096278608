// @ts-check

/**
 * Tries to detect the brand slug based on environment variables _or_ from the URL where the project
 * is hosted.
 */
export function getBrandSlug() {
  // Passing a brand domain by query string is only supported for testing purposes.
  // The storage of this brand domain is easily disrupted by clearing the browser data.
  // Please refrain from using this in production.
  if (process.env.VUE_APP_BRAND_QUERY_STRING) {
    const newBrandDomain = getNewBrandDomainFromQueryString()
    if (newBrandDomain) {
      sessionStorage.setItem('brand_domain', newBrandDomain)
      return newBrandDomain
    }
    // Grab the stored brand_domain from browser cache.
    return sessionStorage.getItem('brand_domain')
  } else {
    const url = new URL(window.location.href)
    const slug = url.hostname.split('.')[0]
    if (slug !== undefined && slug !== null && slug.trim() !== '') {
      return slug
    }
  }

  throw new Error('Unable to determine the brand domain')
}

/**
 @returns {string | null}
 */
export const getNewBrandDomainFromQueryString = () => {
  const url = new URL(window.location.href)
  return url?.searchParams?.get('brand_domain')
}

/**
 * Formats distance in meters to a readable format and adds the abbreviated units. Rounds the value when it exceeds one
 * kilometres
 */
/** @param {number} distance */
export const formatDistance = (distance) => (distance < 1000 ? `${distance} m` : `${Math.round(distance / 1000)} km`)

/**
 * Calls a given function and keeps calling it after the specified delay has passed.
 *
 * Note: This implementation copies [poll][1] to allow our build process to transpile
 * the package.
 *
 * [1]: https://www.npmjs.com/package/poll
 *
 * @param {() => any} fn The function to call.
 * @param {number} delay The delay (in milliseconds) to wait before calling the function again.
 * @param {() => boolean} shouldStopPolling A callback function indicating whether to stop polling.
 */

export async function poll(fn, delay, shouldStopPolling = () => false) {
  if (typeof delay !== 'number') {
    throw new TypeError(`Expected “delay” to be of type number, but it was of type ${typeof delay}.`)
  }

  delay = Math.max(0, delay)

  do {
    await fn()

    if (shouldStopPolling()) {
      break
    }

    await new Promise((resolve) => setTimeout(resolve, delay))
  } while (!shouldStopPolling())
}

/**
 * @param {object} error
 * @param {string} error.name
 */
export function handleRouteRejection(error) {
  if (error && error.name === 'NavigationDuplicated') {
    // The empty catch is because `push` returns a promise and navigating to the same location
    // as the current one is seen as a failure - https://github.com/vuejs/vue-router/issues/2881
  } else {
    return Promise.reject(error)
  }
}

/**
 * @param {object} attributes an object of attributes and their values to be used for the inserted script tag.
 * @returns {Promise<Event>}
 */
export function injectScript(attributes) {
  return new Promise((resolve, reject) => {
    const scriptElement = document.createElement('script')
    scriptElement.type = 'text/javascript'
    scriptElement.addEventListener('load', (event) => resolve(event))
    scriptElement.addEventListener('error', (event) => reject(event))

    if (attributes) {
      for (const [attribute, value] of Object.entries(attributes)) {
        scriptElement.setAttribute(attribute, value)
      }
    }

    const firstScriptElement = document.getElementsByTagName('script')[0]
    if (firstScriptElement instanceof Node) {
      firstScriptElement.parentNode?.insertBefore(scriptElement, firstScriptElement)
    } else {
      document.body.appendChild(scriptElement)
    }
  })
}
