import { createApp } from 'vue'
import createSentryMonitor from './sentry/setup.js'
import { store } from './store/setup.js'
import { router } from './router/setup.js'

import { library } from '@fortawesome/fontawesome-svg-core'
import App from './App.vue'
import {
  faCaretRight,
  faChevronLeft,
  faChevronRight,
  faCoins,
  faExclamationTriangle,
  faHome,
  faMapMarkerAlt,
  faSearch,
  faSpinner,
  faStoreAlt,
  faUndo,
} from '@fortawesome/free-solid-svg-icons'
import { i18n } from './i18n/setup.js'

library.add(
  faCaretRight,
  faChevronLeft,
  faChevronRight,
  faCoins,
  faExclamationTriangle,
  faHome,
  faMapMarkerAlt,
  faSearch,
  faSpinner,
  faStoreAlt,
  faUndo
)

const app = createApp(App)
app.use(store)
app.use(i18n)
app.use(router)

createSentryMonitor(router)

app.mount('#app')
