// @ts-check
import fetch from 'unfetch'

class ResponseError extends Error {
  /**
   *
   * @param {Response} response
   */
  constructor(response) {
    super(response.statusText)
    this.response = response
  }
}

/**
 * Reject the `fetch` promise in case of receiving an HTTP status code describing an error
 *
 * @param {Response} response
 * @return {PromiseLike<Response>}
 */
function checkStatus(response) {
  if (response.ok || (response.status >= 300 && response.status <= 399)) return Promise.resolve(response)
  return Promise.reject(new ResponseError(response))
}

/**
 * Wrap the `fetch` function so that all HTTP errors rejects the promise. This is the
 * most basic building block and can be used to issue arbitrary requests (not related to the main project
 * API)
 *
 * @param  {string} uri
 * @param  {RequestInit} options
 * @return {PromiseLike<Response>}
 */
export function request(uri, options = { method: 'get' }) {
  options.headers = {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    ...options.headers,
  }
  return fetch(uri, options).then(checkStatus)
}

/**
 * Sadly, `URLSearchParams` is not available on IE (all versions) and instead of fiddling with
 * polyfills it's just easier to roll our own poor-man's encoding function.
 * @param {string} path
 * Object to be converted to a url-encoded set of query string parameters
 * @param {Object.<string, string | number | boolean>} searchParams
 * @param {string | undefined} baseUrl API protocol and domain
 */
export function endpoint(path, searchParams = {}, baseUrl = process.env.VUE_APP_API_BASE_URL) {
  const cleanPath = path.replace(/^\/+/, '')
  const queryString = Object.entries(searchParams)
    .map(([key, value]) => {
      const encodedKey = encodeURIComponent(key)
      const encodedValue = encodeURIComponent(value)
      return `${encodedKey}=${encodedValue}`
    })
    .join('&')
  const searchString = queryString.length ? `?${queryString}` : ''
  return `${baseUrl}/${cleanPath}${searchString}`
}

// Aliased functions with prefixed API BASE URL
// ===

/**
 * @typedef {Object} BaseRequestParams
 * @property {string=} baseUrl API protocol and domain to issue a request
 * @property {string} resourcePath API resource path to issue a request
 * @property {Object.<string, string | number>} searchParams query string parameters to be added to the final URI
 * @property {RequestInit} options `fetch` options to overwrite the default behavior of the client
 *
 * @typedef {BaseRequestParams} GetRequestParams
 */

/**
 *
 * @param {string} resourcePath API resource path to issue a GET request
 * @param {Object.<string, string | number>} searchParams query string parameters to be added to the final URI
 * @param {RequestInit} options `fetch` options to overwrite the default behavior of the client
 */
export const get = (resourcePath, searchParams = {}, options = {}) => {
  const mergedOptions = {
    ...options,
    method: 'get',
  }
  return request(endpoint(resourcePath, searchParams), mergedOptions)
}

/**
 * @typedef {Object} Payload
 * @property {Object.<string, string | number | boolean>} payload Object containing the JSON payload to send to the API
 *
 * @typedef {BaseRequestParams & Payload} PostRequestParams
 */

/**
 * HTTP POST request
 *
 * @param {PostRequestParams} props
 */
export function post(
  { baseUrl, resourcePath, payload, options, searchParams } = {
    baseUrl: undefined,
    resourcePath: '',
    payload: {},
    options: {},
    searchParams: {},
  }
) {
  const mergedOptions = {
    ...options,
    method: 'post', // hard-code in case of an accidental override
    body: JSON.stringify(payload),
  }
  return request(endpoint(resourcePath, searchParams, baseUrl), mergedOptions)
}
