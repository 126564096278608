// @ts-check
import { detectUserLanguage, isLocaleLoaded } from '@/i18n/setup.js'
import { APP_CHANGE_USER_LANGUAGE, APP_FETCH_PORTAL_SETTINGS } from '@/store/action.types'
import { getBrandSlug, getNewBrandDomainFromQueryString } from '@/utils.js'
import { APP_SET_ERROR, APP_SET_COMPLETED_ALL_STEPS } from '@/store/mutation.types'
import { ErrorTypes } from '@/constants.js'
import { Mixpanel } from '@/tracking'
import { store } from '../store/setup.js'

/**
 * @typedef {import('vue-router').default} VueRouter
 * @typedef {import('vue-router').NavigationGuard} NavigationGuard
 * @typedef {import('vue-router').Route} Route
 */

/**
 * Wrap the actual guard function with a router instance so that we can use dependency injection to
 * get access to the Vuex store registered in the `router.app` property.
 *
 * @return {NavigationGuard}
 */
export function userLanguageGuard() {
  /**
   * Checks if the user has saved any user language on `localStorage` and if so, consider
   * that language as the source of truth. Otherwise, try to guess the language from the browser's
   * settings, otherwise: fallback to `en`
   *
   * @param {Route} _to route where the user intend navigating to
   * @param {Route} _from route from where the user navigated from
   * @param {Function} next resume the routing from -> to
   */
  return async (_to, _from, next) => {
    const storeUserLanguage = store.state.userLanguage
    const language = detectUserLanguage()
    if (storeUserLanguage !== language || !isLocaleLoaded(language)) {
      try {
        await store.dispatch(APP_CHANGE_USER_LANGUAGE, language)
      } catch (err) {
        // Failed to load. Ignore as we'll fallback to English
      }
    }
    return next()
  }
}

/**
 * Guard all routes so that we inject the last know error into the store. This way we can decouple the rendering
 * logic of the errors from the routing.
 *
 * @return {NavigationGuard}
 */
export function syncErrorToStore() {
  return (to, _from, next) => {
    if (!to.name || to.name !== 'error') {
      // If not routing to error view, carry on.
      store.commit(APP_SET_ERROR, undefined)
    } else {
      const paramType = to.params && to.params.errorType
      const errorType = paramType &&
        typeof paramType === 'string' &&
        Object.values(ErrorTypes).includes(paramType)
        ? paramType : ErrorTypes.UNKNOWN
      store.commit(APP_SET_ERROR, errorType)
    }
    return next()
  }
}

/**
 * Wrap the actual guard function with a router instance so that we can use dependency injection to
 * get access to the Vuex store registered in the `router.app` property.
 *
 * @return {NavigationGuard}
 */
export function portalSettingsGuard() {
  /**
   * @param {Route} to route where the user intend navigating to
   * @param {Route} from route from where the user navigated from
   * @param {Function} next resume the routing from -> to
   */
  return async (to, _from, next) => {
    // If a new brand domain is specified and the env variable is active we need to reload the brand settings.
    const newBrandDomain = getNewBrandDomainFromQueryString()
    // If we have settings _or_ the destination route is the error view, carry on.
    if (
      !process.env.VUE_APP_BRAND_QUERY_STRING &&
      !newBrandDomain &&
      (store.state.settings || (to.params && to.params.errorType === ErrorTypes.UNRECOGNIZABLE_BRAND))
    ) { return next() }

    // Otherwise, try to load the actual settings
    try {
      const language = store.state.userLanguage
      const brandSlug = getBrandSlug()

      if (brandSlug && (!store.state.brand || store.state.brand?.domain !== brandSlug)) {
        await store.dispatch(APP_FETCH_PORTAL_SETTINGS, { brandSlug, language })
      }
    } catch (err) {
      // The brand settings could not be loaded. When not on brand error page redirect to it.
      if (window?.location?.pathname !== '/error/unrecognizable-brand' || window?.location?.search !== '') {
        window.location.assign('/error/unrecognizable-brand')
      }
    }
    // All set. Carry on.
    next()
  }
}

/**
 * After router hook factory. It saves the completion of all steps to the app state.
 *
 * @return {NavigationGuard}
 */
export function saveCompletedSteps() {
  return (to) => {
    if (!to || to.name !== 'summary') return // Do nothing
    // Whenever we navigate
    store.commit(APP_SET_COMPLETED_ALL_STEPS, true)
  }
}

/**
 * The problem: when navigating between routes after scrolling the viewport until the bottom of the page, the next
 * screen rendered keeps the scroll position. This emulates the default behavior of a natural page transition where
 * the user is redirected back to the top of the page.
 */
export const resetViewPortAfterRoute = () => {
  window.scrollTo(0, 0)
}

/**
 *
 * @return {NavigationGuard}
 */
export function registerPageView() {
  /**
   * @param {Route} to
   */
  return () => {
    Mixpanel.page()
  }
}
