<template>
  <div class="wrapper">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'TheWrapper',
}
</script>

<style lang="scss">
@import '@/assets/styles/_variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 2rem 1.875rem;
  max-width: 41.25rem;
  margin: 0 auto;
  .step__main-title {
    text-align: center;
    font-weight: 700;
    font-size: 1.5rem;
  }
  .step__description {
    margin: 1.25rem 0 0;
    text-align: center;
  }

  .card {
    border: $default-border;
    padding: 13px;
    border-radius: $default-border-radius;
    position: relative;
  }
  .card::before {
    content: '';
    border: 3px solid transparent;
    width: calc(100% - 3px);
    height: calc(100% - 3px);
    display: block;
    background-color: transparent;
    border-radius: $default-border-radius;
    top: 0;
    left: 0;
    margin-left: -2px;
    margin-top: -2px;
    position: absolute;
    pointer-events: none;
  }

  .card:focus-within::before {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
  }

  .card--interactive:hover,
  .card--emphasized {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
  }

  .card--emphasized::before {
    border-color: $default-border-color;
  }
}
.entry-message {
  display: flex;
  flex-direction: column;
}
.entry-message__field {
  max-width: 41.25rem;
  border-radius: 6px;
  padding: 13px;
  border: $default-border;

  &:focus {
    box-shadow: $focus-shadow;
  }
}
.entry-message__label {
  display: block;
  margin: 2.25rem auto 1.125rem;
  font-weight: bold;
}
.field-error {
  background-color: #feeaed;
  color: #cc0022;
  padding: 7px;
}
.field-error--mobile {
  @media (min-width: 37.5rem) {
    display: none;
  }
}
.wrapper__container {
  display: flex;
  flex-direction: column;
}
</style>
