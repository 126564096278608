<template>
  <footer class="footer">
    <p
      v-if="returnPolicyOrSupportUrl"
      class="footer__text"
    >
      <a
        class="footer__link"
        :href="returnPolicyOrSupportUrl"
      >{{ $t('Return policy') }}</a>
    </p>
    <slot />
    <UiPromoFooter
      v-if="currentBrand && !currentBrand.hide_powered_by"
      class="footer__powered-by"
    >
      {{ $t('Powered by') }}
    </UiPromoFooter>
  </footer>
</template>
<script>
import { mapGetters } from 'vuex'
import { allUrls } from '@/components/mixins'
import { UiPromoFooter } from '@sendcloud/ui-library'

export default {
  name: 'TheFooter',
  components: {
    UiPromoFooter,
  },
  mixins: [allUrls],
  computed: {
    ...mapGetters(['currentBrand']),
  },
}
</script>
<style lang="scss" scoped>
.footer {
  align-items: center;
  background: #eeeeee;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: auto;
  justify-content: center;
  min-height: 6.25rem;
  padding: 1rem 0;
}
.footer__text {
  margin: 0;
  padding-bottom: 1.25rem;
}
.footer__link {
  color: #212121;
  &:hover {
    color: #000000;
  }
}
.footer__powered-by {
  padding-top: 1.25rem;
}
</style>
