import { mapState } from 'vuex'
function cutHex(hex) {
  return hex.charAt(0) === '#' ? hex.substring(1, 7) : hex
}
function hexToR(hex) {
  return parseInt(cutHex(hex).substring(0, 2), 16)
}
function hexToG(hex) {
  return parseInt(cutHex(hex).substring(2, 4), 16)
}
function hexToB(hex) {
  return parseInt(cutHex(hex).substring(4, 6), 16)
}
export const brandColourMixin = {
  computed: {
    ...mapState({
      brandColour: (state) => {
        if (state.brand) {
          return state.brand.color
        }
      },
    }),
    brandTextColour() {
      if (this.brandColour && !this.disableNext) {
        const threshold = 130

        const hRed = hexToR(this.brandColour)
        const hGreen = hexToG(this.brandColour)
        const hBlue = hexToB(this.brandColour)

        const cBrightness = (hRed * 299 + hGreen * 587 + hBlue * 114) / 1000
        return cBrightness > threshold ? '#000000' : '#ffffff'
      }
      return '#C3C5C7'
    },

    brandDisabledColour() {
      if (this.disableNext) {
        return '#f2f2f2'
      }
      return this.brandColour
    },

    brandStyle() {
      return {
        backgroundColor: this.brandDisabledColour,
        color: this.brandTextColour,
        borderColor: this.disableNext ? '#C3C5C7' : this.brandColour,
      }
    },

    disableNext() { return false },
  },
}
export const allUrls = {
  computed: {
    ...mapState({
      brandWebsite: (state) => {
        if (state.brand) return state.brand.website
        return ''
      },
      returnPolicyUrl: (state) => {
        if (state.settings) return state.settings.return_policy_url
        return ''
      },
      settingsSupportUrl: (state) => {
        if (state.settings) return state.settings.support_url
        return ''
      },
    }),
    allUrls() {
      if (this.returnPolicyUrl) return this.returnPolicyUrl
      if (this.settingsSupportUrl) return this.settingsSupportUrl
      if (this.brandWebsite) return this.brandWebsite
      return ''
    },
    brandWebsiteUrl() {
      if (this.brandWebsite) return this.brandWebsite
      return ''
    },
    returnPolicyOrSupportUrl() {
      if (this.returnPolicyUrl) return this.returnPolicyUrl
      if (this.settingsSupportUrl) return this.settingsSupportUrl
      return ''
    },
    supportUrl() {
      if (this.settingsSupportUrl) return this.settingsSupportUrl
      return ''
    },
  },
}
