import mixpanel from 'mixpanel-browser'

export default {
  allowTracking: false,

  page(data) {
    if (!this.allowTracking) return

    const { title } = document
    this.track(title, data)
  },

  install(installParams) {
    if (!this.allowTracking) return
    mixpanel.init(installParams.token, { api_host: 'https://api-eu.mixpanel.com' })
  },

  track(eventName, eventProperties) {
    if (!this.allowTracking) return

    try {
      mixpanel.track(eventName, eventProperties)
    } catch (error) {
      if (module.hot) {
        // eslint-disable-next-line
        console.error('mixpanel.track() error', error)
      }
    }
  },
}
