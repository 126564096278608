import { createRouter, createWebHistory } from 'vue-router'
import ReturnsSearch from '@/views/ReturnsSearch.vue'
import {
  userLanguageGuard,
  portalSettingsGuard,
  syncErrorToStore,
  saveCompletedSteps,
  resetViewPortAfterRoute,
  registerPageView,
} from './guards'
import { installTracking } from '@/tracking'
const publicPath = process.env.VUE_APP_PUBLIC_PATH || '/'
export const routerConfig = {
  mode: 'history',
  history: createWebHistory(),
  routes: [
    {
      path: `${publicPath}`,
      name: 'home',
      component: ReturnsSearch,
    },
    {
      path: `${publicPath}parcels-number-selection`,
      name: 'parcels-number-selection',
      component: () =>
        import(/* webpackChunkName: "parcels-number-selection" */ '@/views/ParcelsNumberSelectionView.vue'),
    },
    {
      path: `${publicPath}items`,
      name: 'items-selection',
      component: () => import(/* webpackChunkName: "items-selection" */ '@/views/ReturnsItemSelection.vue'),
    },
    {
      path: `${publicPath}reasons/:item?`,
      name: 'return-reasons',
      component: () => import(/* webpackChunkName: "return-reasons" */ '@/views/ReturnReasons.vue'),
    },
    {
      path: `${publicPath}refund`,
      name: 'refund-options',
      component: () => import(/* webpackChunkName: "refund-options" */ '@/views/RefundOptionsView.vue'),
    },
    {
      path: `${publicPath}location`,
      name: 'return-methods',
      component: () => import(/* webpackChunkName: "location" */ '@/views/ReturnsLocation.vue'),
    },
    {
      path: `${publicPath}drop-off-points`,
      name: 'drop-off-points',
      component: () => import(/* webpackChunkName: "drop-off-point" */ '@/views/DropOffPointView.vue'),
    },
    {
      path: `${publicPath}store`,
      name: 'store',
      component: () => import(/* webpackChunkName: "store" */ '@/views/ReturnsStoreLocation.vue'),
    },
    {
      path: `${publicPath}summary`,
      name: 'summary',
      component: () => import(/* webpackChunkName: "summary" */ '@/views/ReturnsSummary.vue'),
    },
    {
      path: `${publicPath}label-creation`,
      name: 'label-creation',
      component: () => import(/* webpackChunkName: "label-creation" */ '@/views/ReturnLabelCreation.vue'),
    },
    {
      path: `${publicPath}confirmation`,
      name: 'confirmation',
      component: () => import(/* webpackChunkName: "confirmation" */ '@/views/ConfirmationView.vue'),
    },
    {
      path: `${publicPath}address`,
      name: 'edit-address',
      component: () => import(/* webpackChunkName: "address" */ '@/views/ReturnsEditAddress.vue'),
    },
    {
      path: `${publicPath}error/:errorType?`,
      name: 'error',
      component: () => import(/* webpackChunkName: "error" */ '@/views/ErrorView.vue'),
    },
  ],
}

export const router = createRouter(routerConfig)

// Before guards
router.beforeEach(userLanguageGuard(router))
router.beforeEach(portalSettingsGuard(router))
router.beforeEach(syncErrorToStore(router))

// After guards
router.afterEach(saveCompletedSteps(router))
router.afterEach(resetViewPortAfterRoute)
router.afterEach(registerPageView(router))
router.isReady().then(installTracking)
