import Mixpanel from './mixpanel.js'
import Hotjar from './hotjar.js'

export function installTracking() {
  if (process.env.VUE_APP_ENABLE_USER_ANALYTICS !== 'true') {
    return
  }

  const thirdParties = [
    { service: Mixpanel, installParams: { token: process.env.VUE_APP_MIXPANEL_TOKEN } },
    { service: Hotjar, installParams: { hotjarId: process.env.VUE_APP_HOTJAR_ID } },
  ]

  thirdParties.forEach((party) => {
    const { service, installParams } = party
    service.allowTracking = navigator.doNotTrack !== '1'
    service.install(installParams)
  })
}

export default { installTracking }
export { Mixpanel }
