<template>
  <TheWrapper class="navigation">
    <nav class="navigation__buttons">
      <UiButton
        v-if="showPreviousButton"
        icon-only
        mode="secondary"
      >
        <button
          type="button"
          @click="previousStep"
        >
          <FontAwesomeIcon icon="chevron-left" />
          <span class="sr-only">{{ $t('Go back to previous step') }}</span>
        </button>
      </UiButton>
      <UiButton
        icon-only
        mode="secondary"
      >
        <button
          type="button"
          @click="restart"
        >
          <FontAwesomeIcon icon="home" />
          <span class="sr-only">{{ $t('Restart return process') }}</span>
        </button>
      </UiButton>
    </nav>
  </TheWrapper>
</template>

<script>
import { handleRouteRejection } from '@/utils'
import { UiButton } from '@sendcloud/ui-library'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import TheWrapper from '@/components/layout/TheWrapper'

export default {
  name: 'TheNavigation',
  components: {
    FontAwesomeIcon,
    UiButton,
    TheWrapper,
  },
  computed: {
    showPreviousButton() {
      if (this.$route.name === 'confirmation') {
        return false
      }
      return true
    },
  },
  methods: {
    previousStep() {
      this.$router.go(-1)
    },
    restart() {
      this.$router.push({ name: 'home' }).catch(handleRouteRejection)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.navigation {
  width: 100%;
  padding-bottom: 0;
}
.navigation__buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;

  .ui-btn > button {
    color: $default-border-color;
    border-width: 2px;
    border-color: $default-border-color;

    &:hover,
    &:active {
      background-color: $icon-hover-fill;
      border-color: $icon-hover-border;
    }

    &:focus {
      box-shadow: $focus-shadow;
      border-color: $icon-hover-border;
    }
  }

  @media (min-width: 37.5rem) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
</style>
