<template>
  <div class="app">
    <InternetExplorerSupport v-if="showInternetExplorerAlert" />
    <TheHeader />
    <TheNavigation v-if="showNavigation" />
    <TheBody>
      <router-view />
    </TheBody>
    <TheFooter>
      <AppLanguageSelector id="language-selector" />
    </TheFooter>
    <div ref="cookie" />
  </div>
</template>

<script>
import 'normalize.css'
import '@sendcloud/ui-library/src/styles/sendcloud.scss'
import * as Sentry from '@sentry/browser'
import { mapGetters } from 'vuex'
import AppLanguageSelector from './components/AppLanguageSelector.vue'
import { privacySupportUrls } from '@/constants'
import { Mixpanel } from '@/tracking'
import TheHeader from './components/layout/TheHeader.vue'
import TheNavigation from './components/layout/TheNavigation.vue'
import TheBody from './components/layout/TheBody.vue'
import TheFooter from './components/layout/TheFooter.vue'
const InternetExplorerSupport = () => import('./components/common/InternetExplorerSupport.vue')

export default {
  name: 'App',
  components: {
    AppLanguageSelector,
    InternetExplorerSupport,
    TheHeader,
    TheNavigation,
    TheBody,
    TheFooter,
  },
  computed: {
    ...mapGetters(['currentBrand', 'userLanguage', 'returnFeeCurrency']),
    showInternetExplorerAlert() {
      return (
        navigator.appName === 'Microsoft Internet Explorer' ||
        !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/))
      )
    },
    showNavigation() {
      if (this.$route === undefined || this.$route.name === null) return false
      return ['home', 'label-creation', 'error'].includes(this.$route.name) !== true
    },
  },
  watch: {
    returnFeeCurrency() {
      this.$i18n.setNumberFormat(this.userLanguage, {
        currency: {
          style: 'currency',
          currency: this.returnFeeCurrency,
        },
      })
    },
    currentBrand() {
      this.updateTitle()
    },
    userLanguage() {
      this.$i18n.locale = this.userLanguage
      this.updateTitle()
      this.initCookieConsent()
    },
  },
  created() {
    Sentry.configureScope((scope) => {
      // Try to add standard information to all events recorded by Sentry as soon as the application is created
      // Some values _may_ be `undefined` depending where an exception occurs in the code, but this is expected
      const { brand, searchParams } = this.$store.state
      const extra = {
        brand: brand !== undefined ? brand.domain : '',
        postal_code: searchParams !== undefined ? searchParams.postal_code : '',
        identifier: searchParams !== undefined ? searchParams.identifier : '',
      }

      Object.entries(extra).forEach(([key, value]) => {
        scope.setExtra(key, value)
      })
    })

    Mixpanel.track('new visit')
  },
  mounted() {
    this.initCookieConsent()
  },
  methods: {
    updateTitle() {
      if (this.currentBrand === undefined || this.currentBrand.name === undefined) {
        // Use the fallback name without a brand name.
        return this.$t('Return portal')
      }

      document.title = this.$t('Return | {brandName}', {
        brandName: this.currentBrand.name,
      })
    },

    initCookieConsent() {
      if (window.cookieconsent && this.$refs.cookie !== undefined) {
        this.$refs.cookie.innerHTML = ''
        window.cookieconsent.initialise({
          container: this.$refs.cookie,
          palette: {
            popup: {
              background: '#f5f7fa',
              text: '#333333',
            },
            button: {
              background: '#ababab',
              text: '#f5f7fa',
            },
          },
          theme: 'edgeless',
          content: {
            message: this.$t('This website uses cookies to ensure you get the best experience.'),
            dismiss: this.$t('Okay'),
            link: this.$t('Learn more'),
            href: privacySupportUrls[this.userLanguage],
          },
        })
      }
    },
  },
}
</script>
<style lang="scss">
@import '@/assets/styles/cookie-consent.scss';
@import './assets/styles/fonts.scss';
* {
  box-sizing: border-box;
}
:root {
  font-size: 1em;
}
.app {
  display: flex;
  flex-direction: column;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1rem;
  height: 100vh;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}
</style>
