<template>
  <div class="language-selector">
    <span class="language-selector__label sr-only">
      {{ $t('Change language') }}
    </span>

    <UiDropdown
      :options="languages"
      :label="userLanguageName"
      class="language-selector__dropdown"
      chevron
      up
      right
      @clicked="changeLanguage"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { APP_CHANGE_USER_LANGUAGE, APP_FETCH_PORTAL_SETTINGS } from '@/store/action.types'

import { AVAILABLE_LANGUAGES } from '../i18n/constants'
import { UiDropdown } from '@sendcloud/ui-library'

export default {
  name: 'AppLanguageSelector',

  components: {
    UiDropdown,
  },

  computed: {
    ...mapGetters(['userLanguage', 'currentBrand']),

    languages() {
      let collator
      if (window.Intl !== undefined && window.Intl.Collator !== undefined) {
        collator = new Intl.Collator(this.userLanguage)
      }
      return Object.entries(AVAILABLE_LANGUAGES)
        .map(([code, name]) => ({ id: code, name, selected: code === this.userLanguage }))
        .sort((languageA, languageB) => {
          return collator !== undefined
            ? collator.compare(languageA.name, languageB.name)
            : languageA.name < languageB.name
        })
    },

    userLanguageName() {
      const [selectedLanguage] = this.languages.filter((language) => language.selected)
      return selectedLanguage.name
    },
  },

  methods: {
    async changeLanguage(code) {
      this.$store.dispatch(APP_CHANGE_USER_LANGUAGE, code)
      await this.$store.dispatch(APP_FETCH_PORTAL_SETTINGS, { language: code, brandSlug: this.currentBrand?.domain })
    },
  },
}
</script>

<style>
.ui-dropdown.ui-dropdown .ui-dropdown__label {
  color: #000;
}

.ui-dropdown.ui-dropdown button,
.ui-dropdown.ui-dropdown .ui-dropdown__list-wrapper {
  border-color: #000;
}
</style>
