import { LANGUAGE_CODE } from '@/i18n/constants.js'

/**
 * @enum {string}
 */
export const ErrorTypes = {
  ANNOUNCEMENT_FAILED: 'announcement-failed',
  COUNTRY_NOT_SUPPORTED: 'country-not-supported',
  NOT_ALLOWED: 'not-allowed',
  EXPIRED_RETURN: 'expired',
  FETCH_MORE_DROP_OFF_POINTS: 'fetch-more-drop-off-points',
  FETCH_DROP_OFF_POINTS_FIRST_PAGE: 'fetch-drop-off-points-first-page',
  FETCH_DROP_OFF_POINTS_NEXT_PAGE: 'fetch-drop-off-points-next-page',
  PARCEL_NOT_FOUND: 'parcel-not-found',
  PARCEL_TOO_HEAVY: 'too_heavy',
  UNKNOWN: 'unknown-error',
  UNRECOGNIZABLE_BRAND: 'unrecognizable-brand',
}

export const privacySupportUrls = {
  [LANGUAGE_CODE.GERMAN_DE]: 'https://www.sendcloud.de/datenschutz/',
  [LANGUAGE_CODE.ENGLISH_UK]: 'https://www.sendcloud.com/privacy-policy/',
  [LANGUAGE_CODE.ENGLISH_US]: 'https://www.sendcloud.com/privacy-policy/',
  [LANGUAGE_CODE.SPANISH_ES]: 'https://www.sendcloud.com/privacy-policy/',
  [LANGUAGE_CODE.FRENCH_FR]: 'https://www.sendcloud.com/privacy-policy/',
  [LANGUAGE_CODE.ITALIAN_IT]: 'https://www.sendcloud.com/privacy-policy/',
  [LANGUAGE_CODE.DUTCH_NL]: 'https://www.sendcloud.nl/privacy-policy/',
}

// TODO: avoid magic numbers, ideally reasons must come from the backend with an extra
// key `require_message: True|False` so that we don't break anything if the IDs differ
export const OTHER_RETURN_REASON_ID = 3
