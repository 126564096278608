<template>
  <TheWrapper>
    <form
      novalidate
      class="wrapper__container search"
      data-test="search-form"
      @submit.prevent="search"
    >
      <h2 class="step__main-title">
        {{ $t('Start your return') }}
      </h2>
      <!-- prettier-ignore -->
      <p class="step__description">
        <!-- eslint-disable max-len, vue/html-indent -->
        {{ $t('Fill in your postal code and the order number or the track & trace number, and return your order in few steps!') }}
        <!-- eslint-enable max-len, vue/html-indent -->
      </p>
      <label
        class="search__label"
        for="postal_code"
      >{{ $t('Postal code') }}</label>
      <input
        id="postal_code"
        v-model="postalCode"
        class="search__input"
        type="text"
        name="postal_code"
        data-test="postal-code-field"
        required
      >
      <label
        class="search__label"
        for="track_trace"
      >{{ $t('Track & trace / Order number') }}</label>
      <input
        id="track_trace"
        v-model="identifier"
        class="search__input"
        type="text"
        name="track_trace"
        data-test="identifier-field"
        required
      >
      <UiButton
        mode="primary"
        class="main-button"
      >
        <button
          type="submit"
          class="main-button__btn"
          data-test="main-button"
          :disabled="disableNext"
          mode="primary"
          :style="brandStyle"
        >
          <span>{{ $t('Start') }}</span>
          <FontAwesomeIcon :icon="$options.faChevronRight" />
        </button>
      </UiButton>
    </form>
  </TheWrapper>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { APP_SEARCH_PARCEL } from '@/store/action.types'
import { brandColourMixin } from '@/components/mixins'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { handleRouteRejection } from '@/utils'
import { UiButton } from '@sendcloud/ui-library'
import TheWrapper from '@/components/layout/TheWrapper'
import { ErrorTypes } from '@/constants.js'
import { privacySupportUrls } from '@/constants'

export default {
  name: 'ReturnsSearchView',
  faChevronRight,
  components: {
    UiButton,
    TheWrapper,
    FontAwesomeIcon,
  },
  mixins: [brandColourMixin],
  data() {
    return {
      postalCode: '',
      identifier: '',
      searching: false,
    }
  },
  computed: {
    ...mapGetters(['userLanguage', 'currentBrand']),
    ...mapState({
      parcel: (state) => state.parcel,
    }),
    disableNext() {
      return this.searching === true || !(this.postalCode.trim() && this.identifier.trim())
    },
    policyUrl() {
      const { locale } = this.$i18n
      return privacySupportUrls[locale]
    },
  },
  /**
   * Check if there are route parameters and dispatch a parcel search immediatelly.
   */
  async created() {
    if (!this.currentBrand) {
      this.$router
        .push({ name: 'error', params: { errorType: ErrorTypes.UNRECOGNIZABLE_BRAND } })
        .catch(handleRouteRejection)
      return
    }

    this.postalCode = String(this.$route.query.postal_code || '').trim()
    this.identifier = String(this.$route.query.identifier || '').trim()

    await this.search()

    if (this.postalCode === '' && this.identifier === '') {
      const savedSearch = this.$store.state.searchParams || {}
      // If nothing came from the URL, try to read it from already saved params.
      this.postalCode = savedSearch.postal_code || ''
      this.identifier = savedSearch.identifier || ''
    }
  },
  methods: {
    async search() {
      if (this.disableNext) return
      this.searching = true
      try {
        const actionPayload = { postal_code: this.postalCode, identifier: this.identifier }
        await this.$store.dispatch(APP_SEARCH_PARCEL, actionPayload)
      } catch (err) {
        this.searching = false
        try {
          let params
          const responseJSON = await err.response.json()
          if (responseJSON.error.code === 'no_methods') {
            params = { errorType: ErrorTypes.COUNTRY_NOT_SUPPORTED }
          } else if (responseJSON.error.code === 'too_old') {
            params = { errorType: ErrorTypes.EXPIRED_RETURN }
          } else if (responseJSON.error.code === 'not_found') {
            params = { errorType: ErrorTypes.PARCEL_NOT_FOUND }
          } else if (responseJSON.error.code === 'too_heavy') {
            params = { errorType: ErrorTypes.PARCEL_TOO_HEAVY }
          } else if (responseJSON.error.code === 'not_allowed') {
            params = { errorType: ErrorTypes.NOT_ALLOWED }
          } else {
            params = { errorType: ErrorTypes.UNKNOWN }
          }
          return this.$router.push({ name: 'error', params }).catch(handleRouteRejection)
        } catch (parseError) {
          return this.$router
            .push({ name: 'error', params: { errorType: ErrorTypes.UNKNOWN } })
            .catch(handleRouteRejection)
        }
      }

      const packingItems = this.$store.state.packingItems

      const firstItem = packingItems.length > 0 && packingItems[0].id !== undefined ? packingItems[0].id : null
      // By default the user is redirected to the returns reason route
      let location = { name: 'return-reasons', params: { item: firstItem } }

      // but if there are more than 2 packingItems, we let the user to select which item to return
      if (this.parcel && this.parcel.collo_count > 1) {
        location = { name: 'parcels-number-selection' }
      }

      const nonReturnableItems = packingItems.filter((item) => item.returnable === false)

      if (packingItems.length >= 2 || (packingItems.length >= 1 && !!nonReturnableItems.length)) {
        location = { name: 'items-selection' }
      }

      this.$router.push(location).catch(handleRouteRejection)
      this.searching = false
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/assets/styles/_variables.scss';

.search__label {
  margin: 2.25rem 0 0.625rem;
  font-weight: 700;
}
.search__input {
  border: $default-border;
  border-radius: $default-border-radius;
  padding: 1rem;

  &:focus {
    box-shadow: $focus-shadow;
  }
}
</style>
