import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { poll } from './../utils.js'
import {
  APP_CHANGE_USER_LANGUAGE,
  APP_CLEAR_SEARCH,
  APP_CREATE_LABEL,
  APP_FETCH_LABEL_STATUS,
  APP_FETCH_PORTAL_SETTINGS,
  APP_SEARCH_PARCEL,
  APP_SELECT_REASON_WITHOUT_PRODUCTS,
  APP_SELECT_RETURNED_ITEMS,
  APP_UPDATE_RETURNED_ITEM_QUANTITY,
  APP_UPDATE_RETURNED_ITEM_REASON,
} from './action.types'
import {
  APP_SET_ACCESS_TOKENS,
  APP_SET_ALLOWED_CARRIERS,
  APP_SET_CHECK_LABEL_STATUS_URL,
  APP_SET_COMPLETED_ALL_STEPS,
  APP_SET_DETACHED_REASON,
  APP_SET_DOWNLOAD_LABEL_URL,
  APP_SET_QR_CODE_URL,
  APP_SET_ERROR,
  APP_SET_LOCATION_TYPE,
  APP_SET_SELECTED_CARRIER_CODE, // TODO: remove when shipping products are implemented
  APP_SET_PACKING_ITEMS,
  APP_SET_CARRIER_DROP_OFF_FINDER,
  APP_SET_PARCEL,
  APP_SET_PARCELS_NUMBER,
  APP_SET_PORTAL_SETTINGS,
  APP_SET_RETURNED_ITEMS,
  APP_SET_SEARCH_PARAMS,
  APP_SET_SELECTED_DROP_OFF_POINT,
  APP_SET_SELECTED_LABELLESS_DROP_OFF_POINT,
  APP_SET_SELECTED_REFUND,
  APP_SET_STORE_ADDRESSES,
  APP_SET_STORE_LOCATION_ID,
  APP_SET_SHIPPING_PRODUCTS,
  APP_SET_USER_LANGUAGE,
  APP_SET_NATIONAL_CARRIER,
  APP_SET_RETURN_ADDRESS,
  APP_SET_BETA_FEATURES,
  APP_SET_APPLICABLE_ACTIONS,
  APP_SET_PICKUP_DATE,
} from './mutation.types'
import { loadLocaleAsync } from '@/i18n/setup'
import { get, post, request } from '@/api/client.js'
import { createReturnPayload, defaultApplicableActions, mapApplicableActionsPayload } from './utils.js'
import { OTHER_RETURN_REASON_ID } from '@/constants.js'
import { DEFAULT_LANGUAGE_CODE } from '@/i18n/constants.js'
/**
 * Explicitly stating this as `stateFactory` (as opposed to `state`) as many Vuex actions/mutations accepts parameters
 * named `state` it's actually easy to introduce references to the global `state` constant instead of requiring such
 * actions/mutations to declare their own `state` arguments.
 */
export const stateFactory = () => ({
  accessToken: '',
  allowedCarriers: undefined,
  brand: undefined,
  returnFeeCurrency: undefined,
  checkLabelStatusURL: undefined,
  completedAllSteps: false,
  deliveryOptions: [],
  detachedReason: undefined,
  downloadLabelURL: undefined,
  errorType: undefined,
  packingItems: [],
  carrierDropOffFinders: {},
  parcel: undefined,
  parcelsNumber: undefined,
  refundOptions: [],
  returnedItems: [],
  returnFee: '',
  returnLocationType: undefined,
  returnReasons: [],
  searchParams: undefined,
  selectedRefund: undefined,
  selectedServicePoint: undefined,
  selectedLabellessServicePoint: undefined,
  selectedStore: undefined,
  servicePointsToken: '',
  shippingProducts: undefined,
  settings: undefined,
  storeAddresses: [],
  userLanguage: DEFAULT_LANGUAGE_CODE,
  selectedCarrierCode: undefined, // TODO: remove when shipping products are implemented
  nationalCarrier: undefined,
  returnAddress: undefined,
  betaFeatures: [],
  applicableActions: defaultApplicableActions,
  pickupDate: undefined,
})

const getters = {
  userLanguage: (state) => state.userLanguage,
  returnFeeCurrency: (state) => state.returnFeeCurrency,
  currentBrand: (state) => state.brand,
  downloadLabelURL: (state) => state.downloadLabelURL,
  qrCodeURL: (state) => state.qrCodeURL,
  returnSuccess: (state) => {
    return (
      state.downloadLabelURL !== undefined ||
      // SC-18385, returning something to store doesn't really creates a label.
      (state.returnLocationType === 'store' && state.selectedStore !== undefined && state.checkLabelStatusURL === null)
    )
  },
  allowedCarrierCodes: (state) => {
    const codes = Object.keys(state.allowedCarriers || {})
    codes.sort()
    return codes
  },
  dropOffPointAddress: (state) => {
    const point = state.selectedServicePoint
    if (!point) return null
    return {
      company_name: point.name,
      address_1: point.street,
      house_number: point.house_number,
      postal_code: point.postal_code,
      city: point.city,
      // NOTE: the service point contains only the country code. We should standardize on how we deal with
      // countries in general.
      country_name: point.country,
      carrier: state.allowedCarriers[point.carrier],
      distance: point.distance,
    }
  },
  labellessDropOffPointAddress: (state) => {
    const point = state.selectedLabellessServicePoint
    if (!point) return null
    return {
      company_name: point.name,
      address_1: point.street,
      house_number: point.house_number,
      postal_code: point.postal_code,
      city: point.city,
      // NOTE: the service point contains only the country code. We should standardize on how we deal with
      // countries in general.
      country_name: point.country,
      carrier: state.allowedCarriers[point.carrier],
      distance: point.distance,
    }
  },
  isInternationalReturn: (state) => {
    return state.returnAddress?.country !== state.parcel?.country.iso_2
  },
}

const mutations = {
  /**
   *
   * @param {Object.<string, any>} state
   * @param {string} errorType
   */
  [APP_SET_ERROR](state, errorType) {
    state.errorType = errorType
  },

  [APP_SET_USER_LANGUAGE](state, newLanguage) {
    try {
      localStorage.setItem('userLanguage', newLanguage)
    } catch (err) {
      // Storage is full or this is private mode (Safari?). Silently ignore as the language _will_ be applied to
      // this session but subsequent visits to the same returns portal will _probably_ default to English
      // as this was never be saved to the `localStorage`.
    }

    state.userLanguage = newLanguage
  },

  [APP_SET_PARCEL](state, parcel) {
    state.parcel = parcel
  },

  [APP_SET_PARCELS_NUMBER](state, parcelsNumber) {
    state.parcelsNumber = parcelsNumber
  },

  [APP_SET_SHIPPING_PRODUCTS](state, shippingProducts) {
    state.shippingProducts = shippingProducts
      ?.map((shippingProduct) => {
        const servicePointCarrierCode = shippingProduct.service_points_carrier || shippingProduct.carrier
        const finderURL = state.carrierDropOffFinders?.[servicePointCarrierCode]
        return {
          ...shippingProduct,
          finderURL,
        }
      })
      // Order the shipping products to have the ERS option as the first one
      .sort((product) => (product.methods.find((method) => method.functionalities.ers) ? -1 : 1))
  },

  /**
   *
   * @param {Object.<string, any>} state
   * @param {Array<ReturnedItem>} items
   */
  [APP_SET_RETURNED_ITEMS](state, items) {
    state.returnedItems = items
  },

  [APP_SET_PORTAL_SETTINGS](state, {
    brand,
    return_fee_currency: returnFeeCurrency,
    delivery_options: deliveryOptions,
    reasons,
    refund_options: refundOptions,
    return_fee: returnFee,
    ...portal
  }) {
    state.returnReasons = reasons
    state.brand = brand
    state.returnFeeCurrency = returnFeeCurrency
    state.returnFee = returnFee
    state.settings = portal
    state.deliveryOptions = deliveryOptions || []

    const allOptions = refundOptions || []
    state.refundOptions = allOptions.map((option) => {
      const { code, label } = option
      return {
        code,
        label,
        requireMessage: option.require_message,
      }
    })
  },

  [APP_SET_PACKING_ITEMS](state, items) {
    state.packingItems = items
  },

  [APP_SET_CARRIER_DROP_OFF_FINDER](state, items) {
    state.carrierDropOffFinders = items
  },

  [APP_SET_STORE_ADDRESSES](state, addresses) {
    state.storeAddresses = addresses
  },

  [APP_SET_ACCESS_TOKENS](state, tokens) {
    state.servicePointsToken = tokens.servicePointsToken
    state.accessToken = tokens.accessToken
  },

  [APP_SET_DETACHED_REASON](state, { reason, message }) {
    if (message && !reason) {
      throw new Error('Unable to save message without a reason')
    }

    const allReasons = state.returnReasons.map((x) => x.id)
    if (!allReasons.includes(reason) && reason !== OTHER_RETURN_REASON_ID) {
      throw new Error('Invalid return reason')
    }

    state.detachedReason = { reason, message: message || '' }
  },

  [APP_SET_SELECTED_REFUND](state, { code, message }) {
    const option = state.refundOptions.find((x) => x.code === code)
    if (!option) {
      throw new Error('Unknown refund option')
    }
    state.selectedRefund = {
      ...option,
      message: message || '',
    }
  },

  [APP_SET_LOCATION_TYPE](state, location) {
    state.returnLocationType = location
  },

  [APP_SET_PICKUP_DATE](state, pickupDate) {
    state.pickupDate = pickupDate
  },

  // TODO: remove when shipping products are implemented
  [APP_SET_SELECTED_CARRIER_CODE](state, carrierCode) {
    state.selectedCarrierCode = carrierCode
  },

  [APP_SET_SELECTED_DROP_OFF_POINT](state, servicePoint) {
    state.selectedServicePoint = servicePoint && {
      ...servicePoint,
      company_name: servicePoint.name,
      address_1: servicePoint.street,
      country_name: servicePoint.country,
      carrier: state.allowedCarriers?.[servicePoint?.carrier],
      carrier_code: servicePoint.carrier,
    }
  },

  [APP_SET_SELECTED_LABELLESS_DROP_OFF_POINT](state, servicePoint) {
    state.selectedLabellessServicePoint = servicePoint && {
      ...servicePoint,
      company_name: servicePoint.name,
      address_1: servicePoint.street,
      country_name: servicePoint.country,
      carrier: state.allowedCarriers?.[servicePoint?.carrier],
      carrier_code: servicePoint.carrier,
    }
  },

  [APP_SET_STORE_LOCATION_ID](state, id) {
    state.selectedStore = id
  },

  [APP_SET_COMPLETED_ALL_STEPS](state, hasCompleted) {
    state.completedAllSteps = hasCompleted
  },

  [APP_SET_DOWNLOAD_LABEL_URL](state, url) {
    state.downloadLabelURL = url
  },

  [APP_SET_QR_CODE_URL](state, url) {
    state.qrCodeURL = url
  },

  [APP_SET_SEARCH_PARAMS](state, params) {
    state.searchParams = params
  },

  [APP_SET_ALLOWED_CARRIERS](state, carriers) {
    if (!Array.isArray(carriers)) {
      throw new TypeError('Invalid carrier list')
    }
    // Map carriers by code
    state.allowedCarriers = carriers.reduce((acc, carrier) => {
      acc[carrier.code] = carrier
      return acc
    }, {})
  },

  [APP_SET_CHECK_LABEL_STATUS_URL](state, url) {
    state.checkLabelStatusURL = url
  },

  [APP_SET_NATIONAL_CARRIER](state, carrier) {
    state.nationalCarrier = carrier
  },

  [APP_SET_APPLICABLE_ACTIONS](state, applicableActions) {
    state.applicableActions = applicableActions
  },

  [APP_SET_RETURN_ADDRESS](state, returnAddress) {
    state.returnAddress = returnAddress
  },

  [APP_SET_BETA_FEATURES](state, betaFeatures) {
    state.betaFeatures = betaFeatures
  },
}

const actions = {
  async [APP_CHANGE_USER_LANGUAGE]({ commit }, locale) {
    await loadLocaleAsync(locale)
    commit(APP_SET_USER_LANGUAGE, locale)
  },

  async [APP_FETCH_PORTAL_SETTINGS]({ commit }, { brandSlug, language }) {
    let response

    try {
      response = await get(`/brand/${brandSlug}/return-portal/`, { language: language || DEFAULT_LANGUAGE_CODE })
    } catch (e) {
      throw new Error('Brand could not be retrieved')
    }

    const responseData = await response.json()
    const { brand, return_address } = responseData.portal
    if (!brand || brand.domain !== brandSlug) {
      throw new Error('Brand domains does not match')
    }

    commit(APP_SET_STORE_ADDRESSES, responseData.return_locations || [])
    commit(APP_SET_PORTAL_SETTINGS, responseData.portal)
    commit(APP_SET_RETURN_ADDRESS, return_address)
    commit(APP_SET_BETA_FEATURES, responseData.enabled_beta_features)
  },

  [APP_CLEAR_SEARCH]({ commit }) {
    commit(APP_SET_SEARCH_PARAMS, undefined)
    commit(APP_SET_ALLOWED_CARRIERS, [])
    commit(APP_SET_PARCEL, undefined)
    commit(APP_SET_SHIPPING_PRODUCTS, undefined)
    commit(APP_SET_PACKING_ITEMS, [])
    commit(APP_SET_ACCESS_TOKENS, { accessToken: '', servicePointsToken: '' })
    commit(APP_SET_SELECTED_DROP_OFF_POINT, undefined)
    commit(APP_SET_SELECTED_LABELLESS_DROP_OFF_POINT, undefined)
    commit(APP_SET_SELECTED_CARRIER_CODE, undefined)
    commit(APP_SET_COMPLETED_ALL_STEPS, false)
    commit(APP_SET_RETURNED_ITEMS, [])
    commit(APP_SET_DOWNLOAD_LABEL_URL, undefined)
    commit(APP_SET_QR_CODE_URL, undefined)
    commit(APP_SET_APPLICABLE_ACTIONS, defaultApplicableActions)
    commit(APP_SET_PICKUP_DATE, undefined)
  },

  /**
   * Search for a parcel matching a postal code and identifier. It would also get the access token
   * that should be used to create labels at the end of the return process.
   */
  async [APP_SEARCH_PARCEL]({ getters, commit, dispatch }, params) {
    await dispatch(APP_CLEAR_SEARCH)
    commit(APP_SET_SEARCH_PARAMS, params)
    const resp = await get(`/brand/${getters.currentBrand.domain}/return-portal/outgoing`, params)
    const responseJSON = await resp.json()

    const accessToken = responseJSON.access_token
    const servicePointsToken = responseJSON.service_points_token
    const responseData = responseJSON.data
    const carrierDropOffFinders = responseData.carrier_drop_off_finder || {}
    const packingItems = responseData.products || []
    const servicePoint = responseData.service_point || null
    const labellessServicePoint = responseData.labelless_service_point || null
    const carriers = responseData.carriers
    const shippingProducts = responseData.shipping_products
    const applicableActions = mapApplicableActionsPayload(responseData.applicable_actions)
    commit(APP_SET_ALLOWED_CARRIERS, carriers)
    commit(APP_SET_PARCEL, responseData.parcel)
    commit(APP_SET_PACKING_ITEMS, packingItems)
    commit(APP_SET_CARRIER_DROP_OFF_FINDER, carrierDropOffFinders)
    commit(APP_SET_SHIPPING_PRODUCTS, shippingProducts)
    commit(APP_SET_ACCESS_TOKENS, { accessToken, servicePointsToken })
    commit(APP_SET_SELECTED_DROP_OFF_POINT, servicePoint)
    commit(APP_SET_SELECTED_LABELLESS_DROP_OFF_POINT, labellessServicePoint)
    commit(APP_SET_COMPLETED_ALL_STEPS, false)
    commit(APP_SET_NATIONAL_CARRIER, responseData.national_carrier)
    commit(APP_SET_APPLICABLE_ACTIONS, applicableActions)

    if (packingItems.length === 1) {
      await dispatch(
        APP_SELECT_RETURNED_ITEMS,
        packingItems.map((item) => item.id)
      )
    }
  },

  /**
   * Given a product id and a selection status, add or remove the product from the selection list,
   * alongside with quantity and return reason
   */
  [APP_SELECT_RETURNED_ITEMS]({ state, commit }, items) {
    /** @type {Array<ReturnedItem>} */
    const returnedItems = state.packingItems
      /* Preserve the packing items order when selecting returned items */
      .filter((item) => items.includes(item.id))
      .map((item) => {
        const returned = state.returnedItems.find((x) => x.id === item.id)
        return {
          id: item.id,
          name: item.name,
          // Preserve values previously set
          quantity: returned ? returned.quantity : item.quantity,
          reason: returned ? returned.reason : undefined,
          message: returned && returned.message ? returned.message : '',
        }
      })

    commit(APP_SET_RETURNED_ITEMS, returnedItems)
  },

  /**
   * Given a returned item id and quantity, updates its value in the list of returned items.
   *
   * @param {ActionContext} context
   * @param {ActionPayload} payload
   */
  [APP_UPDATE_RETURNED_ITEM_QUANTITY]({ state, commit }, { id, quantity }) {
    const maxQuantity = state.packingItems.find((item) => item.id === id).quantity
    const returnedItems = state.returnedItems.map((returned) => {
      let updatedQuantity = returned.quantity
      if (id === returned.id) {
        if (quantity <= 1) {
          updatedQuantity = 1
        } else if (quantity >= maxQuantity) {
          updatedQuantity = maxQuantity
        } else updatedQuantity = quantity
      }
      return {
        ...returned,
        quantity: updatedQuantity,
      }
    })

    commit(APP_SET_RETURNED_ITEMS, returnedItems)
  },

  /**
   * Give a product id and a return reason id, updates the returned item reason
   */
  [APP_UPDATE_RETURNED_ITEM_REASON]({ state, commit }, { id, newReason, newMessage }) {
    const allReasons = state.returnReasons.map((x) => x.id)
    if (newReason !== undefined && newReason !== OTHER_RETURN_REASON_ID && !allReasons.includes(newReason)) {
      throw new Error('Invalid return reason')
    }
    const returnedItems = state.returnedItems.map((returned) => {
      const returnedID = returned.id
      const lookupID = id
      const currentReasonID = returnedID === lookupID ? newReason : returned.reason
      const reason = state.returnReasons.find((entry) => entry.id === currentReasonID)
      const message = returned.id === id ? newMessage || '' : returned.message

      // SC-18612, do not let the app crash if "Other" reason is missing
      const fallbackReasonID =
        returnedID === lookupID && newReason === OTHER_RETURN_REASON_ID ? OTHER_RETURN_REASON_ID : undefined

      return {
        ...returned,
        reason: reason !== undefined ? reason.id : fallbackReasonID,
        message,
      }
    })

    commit(APP_SET_RETURNED_ITEMS, returnedItems)
  },

  [APP_SELECT_REASON_WITHOUT_PRODUCTS]({ commit }, { reason, newMessage }) {
    commit(APP_SET_DETACHED_REASON, { reason, message: newMessage })
  },

  async [APP_CREATE_LABEL]({ commit, state, getters }) {
    const headers = {
      Authorization: `Bearer ${state.accessToken}`,
    }
    const endpoint = `/brand/${getters.currentBrand.domain}/return-portal/incoming`
    const response = await post({
      resourcePath: endpoint,
      payload: createReturnPayload(state),
      options: { headers },
    })
    const { poller_url } = await response.json()
    commit(APP_SET_CHECK_LABEL_STATUS_URL, poller_url)
  },

  /**
   * Keep querying the URL provided by the label creation endpoint to see if a label was already created.
   */
  async [APP_FETCH_LABEL_STATUS]({ commit, state, getters }, { maxTries, delay }) {
    if (!state.checkLabelStatusURL) throw new Error('Undefined label reference to poll.')
    let tries = 0
    const shouldStopPolling = () => {
      return getters.returnSuccess === true
    }

    return new Promise((resolve, reject) => {
      const queryLabelStatus = async () => {
        if (tries >= maxTries) {
          return reject(new Error('Maximum tries to fetch the label URL exceeded'))
        }
        try {
          const headers = {
            Authorization: `Bearer ${state.accessToken}`,
          }
          const response = await request(state.checkLabelStatusURL, { headers })
          if (response.status !== 200) {
            throw new Error('The label is not ready yet. Keep going')
          }

          const { download_url, qr_code_url } = await response.json()
          commit(APP_SET_DOWNLOAD_LABEL_URL, download_url)
          commit(APP_SET_QR_CODE_URL, qr_code_url)
          resolve(true)
        } catch (err) {
          if (err.response && err.response.status >= 400) {
            // Apparently, rejecting is not enough and the poller keeps trying to make requests. Let's
            // force it to stop then.
            tries = maxTries
            return reject(err)
          }
          // explicitly ignoring the rest so we can reach the maxTries.
        } finally {
          tries += 1
        }
      }

      poll(queryLabelStatus, delay, shouldStopPolling)
    })
  },
}

const persistOptions = {
  storage: sessionStorage,
  // Keep all top-level state keys but the language because it's already saved in the localStorage,
  // there is no need to keep it in the sessionStorage as well.
  paths: Object.keys(stateFactory()).filter((key) => key !== 'userLanguage'),

  // Also, only persist to sessionStorage if it's not a language change
  filter: ({ type }) => type !== APP_SET_USER_LANGUAGE,
}

const plugins = []
if (process.env.NODE_ENV !== 'test') {
  // vuex-persistedstate leaks data from one `localVue` instance to the next. Here we avoid
  // registering it when running tests and if/when we need to check behaviour based on
  // vuex-persistedstate inside unit tests, then we can explicitly add it to the code under test.
  plugins.push(createPersistedState(persistOptions))
}

export const storeConfig = {
  state: stateFactory,
  getters,
  mutations,
  actions,
  plugins,
}

/**
 * Create the store and register Vuex in the given Vue instance without polluting the global `Vue`
 * instance.
 */
export const store = createStore(storeConfig)
