import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

export default function createSentryMonitor(router) {
  const { VUE_APP_SENTRY_ENVIRONMENT, VUE_APP_RELEASE, VUE_APP_SENTRY_DSN, VUE_APP_SENTRY_ORIGINS } = process.env

  let tracingOrigins = ['*.shipping-portal.com']
  if (VUE_APP_SENTRY_ORIGINS) {
    tracingOrigins = VUE_APP_SENTRY_ORIGINS.split(',')
  }

  if (!VUE_APP_SENTRY_DSN) {
    return
  }
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins,
      }),
    ],
    release: VUE_APP_RELEASE,
    environment: VUE_APP_SENTRY_ENVIRONMENT,
    blacklistUrls: [
      /.*hotjar\.com.*/,
      /.*google-analytics\.com.*/,
      /.*jsdelivr\.net.*/,
      /graph\.facebook\.com/i,
      /connect\.facebook\.net\/en_US\/all\.js/i,
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      /extensions\//i,
      /^chrome:\/\//i,
      /127\.0\.0\.1:4001\/isrunning/i,
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
    ignoreErrors: ['Non-Error exception captured', 'Non-Error promise rejection captured'],
  })
}
