<template>
  <header class="header">
    <a
      v-if="currentBrand"
      :href="currentBrand.website"
    ><img
      v-if="brandLogo"
      :src="brandLogo"
      :alt="currentBrand.name"
      class="header__img"
    ></a>
  </header>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'TheHeader',
  computed: {
    ...mapGetters(['currentBrand']),
    brandLogo() {
      return this.currentBrand && this.currentBrand.screen_logo && this.currentBrand.screen_logo.url
    },
  },
}
</script>
<style lang="scss" scoped>
.header {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: 6.25rem;
  justify-content: center;
  @media (min-width: 1600px) {
    height: 7.5rem;
  }
}
.header__img {
  margin: 0.625rem;
  max-height: 3.75rem;
  @media (min-width: 1600px) {
    margin: 1.25rem;
    max-height: 5rem;
  }
}
</style>
