import { i18n } from '@/i18n/setup.js'

/**
 * Factory function to create a payload to be sent in the return creation endpoint
 * based in the app state
 */

const getReturnServicePoint = (state) => {
  let servicePoint = null

  if (state.returnLocationType === 'dropoff') {
    servicePoint = state.selectedServicePoint
  }

  if (state.returnLocationType === 'drop_off_labelless') {
    servicePoint = state.selectedLabellessServicePoint
  }

  if (servicePoint) {
    const { carrier_code: carrierCode, ...servicePointWithoutCarrierCode } = servicePoint
    return {
      ...servicePointWithoutCarrierCode,
      carrier: servicePoint?.carrier?.code || servicePoint?.carrier || carrierCode,
    }
  }

  return servicePoint
}

export function createReturnPayload(state) {
  if (state === undefined) throw new Error('Invalid application state')
  if (state.parcel === undefined) throw new Error('No parcel defined')
  const returnedItems = state.returnedItems || []
  const invalidDetached = state.detachedReason === undefined || state.detachedReason.reason === undefined

  if (invalidDetached && returnedItems.every((x) => x.reason === undefined)) {
    throw new Error('No return reason defined')
  }

  const DeliveryMap = {
    store: 'in_store',
    'post-office': 'drop_off_point',
    dropoff: 'drop_off_point',
    drop_off_labelless: 'drop_off_labelless',
  }

  const storeLocationId = state.returnLocationType === 'store' ? state.selectedStore : undefined
  const products = state.packingItems || []
  let refund = {
    refund_type: { code: 'money' },
    message: '',
  }
  if (state.selectedRefund !== undefined) {
    refund = {
      refund_type: { code: state.selectedRefund.code },
      message: state.selectedRefund.message || '',
    }
  }
  let selectedServicePointFriendlyCarrierCode
  const selectedFunctionalities = {}
  if (state.selectedCarrierCode) {
    const shippingProductsForSelectedCarrier = state.shippingProducts.filter(
      (product) => product.carrier === state.selectedCarrierCode
    )
    selectedServicePointFriendlyCarrierCode = shippingProductsForSelectedCarrier[0]?.service_points_carrier

    if (
      shippingProductsForSelectedCarrier.find((shippingProduct) =>
        shippingProduct.methods.some((method) => method.functionalities.ers === true)
      )
    ) {
      selectedFunctionalities.ers = [true]
    }
  }

  if (state.returnLocationType === 'drop_off_labelless') {
    selectedFunctionalities.labelless = [true]
  }

  let pickUpTimeStamp = null

  if (state.returnLocationType === 'pickup') {
    selectedFunctionalities.first_mile = 'pickup'
    pickUpTimeStamp = state.pickupDate.next_business_day
  }

  const payload = {
    reason: (state.detachedReason && state.detachedReason.reason) || null,
    message: (state.detachedReason && state.detachedReason.message) || '',
    outgoing_parcel: state.parcel.id,
    service_point: getReturnServicePoint(state),
    refund,
    delivery_option: DeliveryMap[state.returnLocationType] || state.returnLocationType,
    selected_carrier_code: selectedServicePointFriendlyCarrierCode,
    products: returnedItems.map((item) => {
      const product = products.find((x) => x.id === item.id)
      if (product === undefined) throw new Error('Unable to match products and returned items')
      if (item.reason === undefined) throw new Error('All returned items must have a return reason')
      return {
        product_id: item.id,
        quantity: item.quantity,
        description: product.name,
        // SC-17220, this field doesn't accept `null`, so we default to `0`
        value: product.price || 0,
        return_reason: item.reason,
        return_message: item.message || null,
      }
    }),
    incoming_parcel: {
      collo_count: state.parcelsNumber || null,
      from_address_1: state.parcel.address_divided.street,
      from_address_2: state.parcel.address_2,
      from_city: state.parcel.city,
      from_company_name: state.parcel.company_name,
      from_country: state.parcel.country.iso_2,
      from_email: state.parcel.email,
      // SC-18538, house number _may_ be blank in certain countries
      from_house_number: state.parcel.address_divided.house_number || i18n.t('n/a'),
      from_name: state.parcel.name,
      from_postal_code: state.parcel.postal_code,
      from_telephone: state.parcel.telephone,
    },
    store_location: storeLocationId,
    selected_functionalities: Object.keys(selectedFunctionalities).length > 0 ? selectedFunctionalities : undefined,
  }

  if (payload.service_point === null) {
    payload.selected_carrier_code = state.selectedCarrierCode
  }

  if (pickUpTimeStamp) {
    payload.pickup_date = pickUpTimeStamp
  }

  return payload
}

export const defaultApplicableActions = { changeReturnFee: undefined }
export function mapApplicableActionsPayload(applicableActionsPayload = []) {
  defaultApplicableActions.changeReturnFee = applicableActionsPayload.reverse().find((applicableAction) => {
    return applicableAction.id === 'change_return_fee'
  })?.value

  return defaultApplicableActions
}
