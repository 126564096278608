export const APP_CHANGE_USER_LANGUAGE = 'APP_CHANGE_USER_LANGUAGE'
export const APP_CLEAR_SEARCH = 'APP_CLEAR_SEARCH'
export const APP_CREATE_LABEL = 'APP_CREATE_LABEL'
export const APP_FETCH_DROP_OFF_POINTS = 'APP_FETCH_DROP_OFF_POINTS'
export const APP_FETCH_LABEL_STATUS = 'APP_FETCH_LABEL_STATUS'
export const APP_FETCH_PORTAL_SETTINGS = 'APP_FETCH_PORTAL_SETTINGS'
export const APP_SEARCH_PARCEL = 'APP_SEARCH_PARCEL'
export const APP_SELECT_REASON_WITHOUT_PRODUCTS = 'APP_SELECT_REASON_WITHOUT_PRODUCTS'
export const APP_SELECT_RETURNED_ITEMS = 'APP_SELECT_RETURNED_ITEMS'
export const APP_UPDATE_RETURNED_ITEM_QUANTITY = 'APP_UPDATE_RETURNED_ITEM_QUANTITY'
export const APP_UPDATE_RETURNED_ITEM_REASON = 'APP_UPDATE_RETURNED_ITEM_REASON'
