// @ts-check
import { createI18n } from 'vue-i18n/index'

import messages from '@/locales/en-US.json'
import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE_CODE } from './constants.js'

/** @type {Array.<string>} */
const loadedLocales = [DEFAULT_LANGUAGE_CODE] // our default language that is preloaded
const loadedMessages = {
  [DEFAULT_LANGUAGE_CODE]: messages,
} // our default language that is preloaded

const numberFormats = {
  [DEFAULT_LANGUAGE_CODE]: {
    currency: {
      style: 'currency',
      currency: 'EUR',
    },
  },
}

export const i18n = createI18n({
  mode: 'legacy',
  locale: DEFAULT_LANGUAGE_CODE,
  fallbackLocale: DEFAULT_LANGUAGE_CODE,
  messages: loadedMessages,
  numberFormats,
})

/**
 * @param {string} locale
 */
function setI18nLocale(locale) {
  i18n.locale = locale
  // @var {HTMLElement | null}
  const html = document.querySelector('html')
  if (html) {
    html.setAttribute('lang', locale)
  }
  return locale
}

/**
 * If the user has saved any value as its preference for language on `localStorage`, then we use it
 * as the source of truth, otherwise we try to read the language from the browser's settings. If neither
 * is supported, then we default to English.
 *
 * @returns {string}
 */
export function detectUserLanguage() {
  try {
    const savedLanguage = localStorage.getItem('userLanguage')

    if (savedLanguage !== null && savedLanguage in AVAILABLE_LANGUAGES) {
      return savedLanguage
    }

    const browserLanguage = 'language' in window.navigator ? window.navigator.language : null

    if (typeof browserLanguage === 'string') {
      if (browserLanguage.length === 5) {
        const [lang, region] = browserLanguage.split('-')
        const normalizedBrowserLanguage = `${lang}-${region.toUpperCase()}`

        if (normalizedBrowserLanguage in AVAILABLE_LANGUAGES) {
          return normalizedBrowserLanguage
        }
      } else if (browserLanguage.length === 2) {
        for (const locale of Object.keys(AVAILABLE_LANGUAGES)) {
          if (locale.startsWith(browserLanguage)) {
            return locale
          }
        }
      }
    }

    return DEFAULT_LANGUAGE_CODE
  } catch {
    return DEFAULT_LANGUAGE_CODE
  }
}

/**
 * Check if a certain language was already loaded at some point of the app lifecycle.
 *
 * @param {string} locale
 * @returns {boolean}
 */
export function isLocaleLoaded(locale) {
  return loadedLocales.includes(locale)
}

/**
 *
 * @param {string} locale
 * @returns {PromiseLike<string>}
 */
export function loadLocaleAsync(locale) {
  if (i18n.locale !== locale) {
    if (!isLocaleLoaded(locale)) {
      return import(/* webpackChunkName: "lang-[request]" */ `@/locales/${locale}.json`).then((msgs) => {
        loadedMessages[locale] = msgs.default
        loadedLocales.push(locale)
        return setI18nLocale(locale)
      })
    }
    return Promise.resolve(setI18nLocale(locale))
  }
  return Promise.resolve(locale)
}
