<template>
  <div class="the-body">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'TheBody',
}
</script>

<style scoped lang="scss">
.the-body {
  flex-grow: 1;
}
</style>

<style lang="scss">
@import '@/assets/styles/_variables.scss';
.main-button {
  margin-top: 2.25rem;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
}
.main-button__mock {
  border-radius: 3px;
  height: 43px;
  background-color: #f2f2f2;
  border: 1px solid currentColor;
  color: #c3c5c7;
  cursor: not-allowed;
  font-size: 15px;
  text-decoration: none;
  vertical-align: middle;
  font-weight: bold;
  font-family: inherit;
  display: inline-block;
  padding: 0 17px;
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ui-btn {
  .main-button__btn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      flex: 1;
    }
  }

  .fa-chevron-right {
    float: right;
  }
}
.ui-btn.ui-btn > .small-button__btn {
  border-color: #c4c4c4;
  color: #000;
  font-weight: 400;
  &:hover {
    background: lighten(#000, 95%);
    border-color: #c4c4c4;
  }
}
.ui-btn.ui-btn > .secondary-button__btn {
  border-color: #212121;
  color: #000;
  &:hover {
    background: lighten(#000, 95%);
    border-color: #212121;
  }
}

.ui-btn.ui-btn--secondary.ui-btn--icon-only > button:hover,
.ui-btn.ui-btn--secondary.ui-btn--icon-only > button:active,
.ui-btn.ui-btn--secondary.ui-btn--icon-only > button:focus {
  background-color: #fff;
  border: $default-border;
}

.ui-btn.ui-btn > a.secondary-button__btn {
  margin-top: 1.25rem;
}

.ui-btn.ui-btn > .main-button__btn {
  height: 50px;
}

.number-stepper-override .ui-number-stepper__wrapper input.ui-number-stepper__input {
  font-size: 1.25rem;
}
.number-stepper-override button.ui-number-stepper__btn {
  height: 2.625rem;
  width: 3.25rem;
}
.number-stepper-override button.ui-number-stepper__btn:not([disabled]) {
  background: #212121;
  border-color: #212121;
}
.number-stepper-override button.ui-number-stepper__btn:not([disabled]):focus,
.number-stepper-override button.ui-number-stepper__btn:not([disabled]):hover {
  background: lighten(#212121, 20%);
}
.number-stepper-override label.ui-number-stepper__label {
  margin: 2.125rem auto 1.125rem;
}
</style>
